import React from "react";

export default props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 115">
            <path d="M469.644 9.515l8.784 9.515 29.036-.265L536.5 18.5l.288-9.25.287-9.25H460.86l8.784 9.515M585 13.71v12.71l28.75 28.845 28.75 28.844 19.206-19.804L680.911 44.5l.045 35.286.044 35.286 9.75-.286 9.75-.286.007-56.75c.004-31.213-.347-56.75-.779-56.75-.432 0-13.345 12.713-28.695 28.25L643.124 57.5l-28.319-28.25C599.23 13.712 586.152 1 585.744 1c-.409 0-.744 5.72-.744 12.71M76.763 35.419L76.5 68.838l-33-28.998C25.35 23.892 8.363 9.036 5.75 6.828L1 2.814l.027 14.343.027 14.343 46.15 41c25.383 22.55 46.521 41.15 46.973 41.333.453.184.823-24.904.823-55.75V2H77.027l-.264 33.419M179 3.583c-10.303 2.718-18.019 7.261-26.04 15.331-22.686 22.824-22.506 57.048.418 79.589 35.786 35.188 96.025 10.093 96.025-40.003 0-26.077-17.481-48.498-42.863-54.978-7.398-1.889-20.258-1.861-27.54.061m108 7.405v8.989l38.343.261 38.344.262-18.739 18c-10.306 9.9-18.785 18.337-18.843 18.75-.058.413 5.861.75 13.152.75h13.256L380.5 30l27.987-28H287v8.988m-104 9.605c-12.448 3.308-23.657 13.829-27.395 25.713-2.664 8.468-1.958 21.297 1.591 28.912 3.727 7.998 10.769 15.169 18.715 19.058C182.061 97.285 183.2 97.5 193 97.5c9.314 0 11.15-.304 16.253-2.696 16.079-7.535 25.805-25.333 22.889-41.887-2.237-12.703-9.497-23.189-19.821-28.631-9.887-5.212-19.194-6.384-29.321-3.693m278 35.895V66H537.075l-.287-9.25-.288-9.25-37.75-.262-37.75-.262v9.512M1 95.45V115h18V92.207l-7.579-7.354c-4.169-4.044-8.219-7.713-9-8.153C1.214 76.021 1 78.85 1 95.45m295 10.05l-9.46 9.5H409V96H305.46l-9.46 9.5m173.674.011L460.847 115h76.228l-.287-9.25-.288-9.25-29-.239-29-.239-8.826 9.489" fill="white" fill-rule="evenodd" xmlns="http://www.w3.org/2000/svg"/>
        </svg>
    )
};

